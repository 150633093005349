// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyDid3ZncIBbrkIlPC9pZw9g8rimwVzWhQ0",
    authDomain: "blackdog-test.firebaseapp.com",
    databaseURL: "https://blackdog-test.firebaseio.com",
    projectId: "blackdog-test",
    storageBucket: "blackdog-test.appspot.com",
    messagingSenderId: "442703185386",
    appId: "1:442703185386:web:f0b0ba73124ec11d931e93",
    measurementId: "G-SYKVEVX48R"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
