import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

// Decorator o Metadata
@Component({
  // nombre del tag usando en HTML <app-root>
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
// Class
export class AppComponent {
  // Properties
  // property name: data type [= default value];
  title: string = 'blackdog-web';
  //items: Observable<any[]>;

  // constructor(firestore: AngularFirestore) {    
  //   this.items = firestore.collection('items').valueChanges();
  // }
}
